import React from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";


function About() {
    return (
        <div id="details">
            <MetaTags>
                <title>Safekeeping your will - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="Safekeeping your will - Life Time Wills" />
            </MetaTags>
            <h1 className="page_title">Safekeeping of Your Will</h1>
            {/*<div className="row">
                <div className="col-md-12">
                    <img src="/images/title_safe.gif" alt="keeping you will safe" />
                </div>
            </div>*/}

            <div className="clearfix no_margin">
            <div id="left_col">
                <p>There is no formal requirement as to where
                    your Will should be stored. However, it is advisable to keep your Will
                    in a safe place, either at home or lodged with a bank, or with
                    a legal advisor, which is easily accessible. It is not advisable to keep a
                    Will in a safety deposit box because after your death your executors will
                    not be able to open that box without obtaining a court order. </p>
                <p>Alternatively, and more securely, your original Will
                    may be deposited at the Probate Registry. This has several advantages,
                    the most important being that your Will is guaranteed to be held securely.
                    Since your Will is recorded, no attempt can be made to administer your
                    estate by bypassing your Will.</p>
                <p>It is sensible to keep a photocopy of your Will in case
                    the original is accidentally lost or destroyed but it should be clear
                    that it is only a copy (clearly mark it with "copy" on all pages)
                    and make a note on the copy where the original is kept. Do not staple,
                    clip or attach any other documents to your original Will.</p>
                <p>Finally, once you have decided where your Will should
                    be stored make sure that your executors and a member of your family knows
                    of your Will’s whereabouts.</p>
            </div>
            <div id="right_col">
                <p className="side_top">There are 4 Will Storage Options:</p>
                <div className="side_mid">
                    <p>Single LifeTime Will Storage with</p>
                    <p className="noTop">LifeTime Wills UK <strong>£79</strong></p>
                    <p>Mirror LifeTime Will Storage with</p>
                    <p className="noTop">LifeTime Wills UK <strong>£139</strong></p>
                    <p>Single LifeTime Will Storage</p>
                    <p className="noTop">at the Probate Registry <strong>£99</strong></p>
                    <p>Mirror LifeTime Will Storage</p>
                    <p className="noTop">at the Probate Registry <strong>£149</strong></p>
                </div>
                <div className="side_bot"></div>
                <p className="notage">NOTE: Please choose a storage option AFTER you have purchased your Will</p>
            </div>
            </div>
                <div id="more_info" className="clearfix no_margin">
                    <p>First, order your will, then choose your storage by clicking the 'Order Now' button…</p>
                    <Link to="/order-now.html" >
                        <img name="Image13" border="0" src="/images/order.gif" alt="next >" />
                    </Link>
                </div>
            </div>
        );
}

export default About;