import React from "react";
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import $ from 'jquery';

function HeaderButton (props) {
    let stepNumber = "Step " + (props.indexValue + 1);
    return (
        <Link to={props.linkTo} href="#" onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} >
            <img src={props.value} onClick={props.onClick} alt={stepNumber} />
        </Link>
    );
}

class MenuBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: ['../images/banner1.gif', '../images/banner2.gif', '../images/banner3.gif', '../images/space.gif'],
            steps: ["../images/nav_step1.gif","../images/nav_step2.gif","../images/nav_step3.gif"],
            steplink: ["step1.html","step2.html","step3.html"],
            sideMenuImg: ["../images/picture6.png","../images/picture4.png","../images/picture9.png"]
        };
    }

    handleMouseOut(){
        $("#banner img").attr("src", "../images/space.gif");
    }

    handleMouseOver(index){
        $("#banner img").attr("src",this.state.items[index]);
    }

    handleOnClick(index){
        $(".box-img-menu img").attr("src",this.state.sideMenuImg[index]);
    }

    renderButton(index){
        return (
          <HeaderButton
            value={this.state.steps[index]}
            onMouseOver={() => this.handleMouseOver(index)}
            onMouseOut={() => this.handleMouseOut(index)}
            onClick={() => this.handleOnClick(index)}
            linkTo={this.state.steplink[index]}
            indexValue={index}
            />
        );
    }

    render() {
        return (
            <div className="sub-menu">
                <img src={"../images/3_easy.gif"} className="easy-step" alt="3 easy steps to make your will"/>
                {/*<img src={"../images/lifetime_logo2.jpg"} className="float-right life-time-img" alt="header img bottom"/>*/}
                <div className="three-steps float-right">
                    {this.renderButton(0)}
                    {this.renderButton(1)}
                    {this.renderButton(2)}
                </div>
            </div>
        );
    }
}

export default withRouter(MenuBtn);