import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import MetaTags from "react-meta-tags";

function changeImage(image){
    $(".box-img-menu img").attr("src", "../images/picture" + image + ".png");
}

function Option1() {
    return (
        <div id="details">
            <MetaTags>
                <title>Option 2 - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="Option 2 - Life Time Wills" />
            </MetaTags>
            <h1 className="page_title">Option 2 - Instant Will Plus</h1>
            {/*<img src="/images/title_opt2.gif" alt="" />*/}
            <p>Your Last Will and Testament can be completed online
                now for just <strong>£69</strong> (<strong>£99</strong> for
                mirrored Wills). <strong>Have your completed Will reviewed by experts
                    and sent to you by 1st Class Post within 48 hours.</strong></p>
            <p>This is the simplest, best quality,
                value for money Will making solution available online in the UK. No legal
                jargon and expensive dialogue, the service provides you with FREE email
                and phone support plus comprehensive guidance notes on how to complete
                your Will.</p>
            <ul>
                <li><strong>Reviewed by Experts</strong> - Your Will is checked by experts.</li>
                <li><strong>Comprehensive Guidance Notes</strong> – No other service provides such comprehensive
                    guides.
                </li>
                <li><strong>Quality Advice</strong> - Be confident that the procedure you are following is
                    appropriate and legally correct.
                </li>
                <li><strong>FREE Consultation</strong> – If you are uncertain as to whether our service is right for
                    you please contact us.
                </li>
                <li><strong>FREE Email / Phone Support</strong> – FREE help and advice throughout the whole process.
                </li>
                <li><strong>FREE Updates &amp; Upgrades</strong> – Immediate notification/upgrade of law or
                    documentation requirements.
                </li>
                <li><strong>FREE Postage</strong> – Unlike other services, we won’t charge extra to post you your
                    Will.
                </li>
                <li><strong>FREE Financial Advice</strong> – FREE advice on any financial aspect.</li>
                <li><strong>FREE Guide </strong> - “Essential Guide to Inheritance Tax” Book (rrp £16.99).</li>
                <li><strong>50% Discount</strong> - Save money on your Will storage.</li>
            </ul>
            <p><strong>Unbeatable, No Risk, Money-Back Guarantee:</strong>
                If, within 30 days of purchase, you find another UK online Will product
                that offers the same quality of service, documentation and support, for less money, you'll receive
                a <strong>no-quibble 100% refund
                    GUARANTEED.</strong></p>
            <p><strong>To preview the unique, easy-to-use online forms</strong> click the 'next' button…</p>
            <div id="more_info" className="more_info">
                <Link to="/order-now.html" className="fleft"><img
                    src="/images/order.gif" name="Image13" border="0" alt="order now" />
               </Link>
                <Link onClick={() => changeImage(2)} to="/example-form.html" >
                    <img src="/images/next.gif" name="Image12" className="Image12" border="0" alt="next >" />
                </Link>
            </div>
        </div>
        );
}

export default Option1;