import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import MetaTags from "react-meta-tags";

function changeImage(image){
    $(".box-img-menu img").attr("src", "../images/picture" + image + ".png");
}

function chnageNextImage(image){
    if(image){
        $("img[name='Image1']").attr("src", "/images/next_over.gif");
    } else {
        $("img[name='Image1']").attr("src", "/images/next.gif");
    }
}

function About() {
    return (
        <div id="details">
            <MetaTags>
                <title>Should I make a Will - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="About us - Life Time Wills" />
            </MetaTags>
            <h1 className="page_title">About Wills</h1>
            <div className="row">
                <div className="col-md-6">
                    <img src="/images/title_should.gif" alt="" title="Title"/>
                        <p>The only qualifications necessary are that you are of
                            legal age (18) and of sound mind. If there is a history of mental disorder
                            of if an illness may be affecting your judgement in any way, you should
                            consult a qualified doctor just before preparing your Will. This will
                            help establish your competence and will be useful should your Will be
                            contested later on the grounds of mental incapacity.
                        </p>
                        <p>If you are married, both you and your spouse should prepare
                            Wills. This is true even if marital assets are primarily in the name of
                            one spouse. Usually you will wish to name your spouse as your main beneficiary
                            and include a substitutional gift to take effect if he or she predeceases
                            you.
                        </p>
                        <p>If you are not married but are living with someone and
                            you want that person to benefit from your estate, it is particularly important
                            to make a Will. This is so because the rules of intestacy make no provision
                            for unmarried partners. If you were to die intestate, your partner would
                            receive nothing from your estate.
                        </p>
                </div>
                <div className="col-md-6">
                    <img src="/images/title_how.gif" alt="How long is a Will Valid?" />
                    <p>Once prepared, your Will is valid until revoked, which may occur in one
                        of <strong>three</strong> ways:</p>
                    <ol>
                        <li>By destruction, combined with the intention to revoke.</li>
                        <li>By making a new Will that revokes the old Will. The Life-Time Will
                            form contains the phrase, 'I revoke all previous Wills and codicils'
                            in order to do this.
                        </li>
                        <li>By marriage or re-marriage, unless your Will expressly states that
                            it is made in contemplation of that forthcoming marriage.
                        </li>
                    </ol>
                    <p>Other than in one of these circumstances, your Will remains valid for an unlimited period of
                        time. </p>
                    <p className="emph">Note: A divorce does not revoke a Will.</p>
                </div>
            </div>
            <div className="clear">
                <br id="more_info" className="clearfix"/>
                <p><strong>Why should I make a Will?</strong> Click on the 'next' button…</p>
                <Link onMouseOut={() => chnageNextImage(0)} onMouseOver={() => chnageNextImage(1)}  onClick={() => changeImage(3)} to="/about-wills2.html" >
                    <img name="Image1" border="0" src="/images/next.gif" alt="next >" />
                </Link>
            </div>
        </div>
        );
}

export default About;