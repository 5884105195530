import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import MetaTags from "react-meta-tags";
import './mobile_home_style.css';

function chnageNextImage(image){
    if(image){
        $("img[name='Image1']").attr("src", "/images/next_over.gif");
    } else {
        $("img[name='Image1']").attr("src", "/images/next.gif");
    }
}
// please don't delete this code form here,this code for homepage mobile view

const css = `
@media screen and (max-width: 767px){
    .content.index_all{
        padding: 0px;
        background: transparent;
    }
    .box-top div:nth-of-type(2){
        
    }
    .promo_item::after{
        display: none;
    }
    .promo_item{
        padding:20px!important;border-radius: 6px!important;margin-bottom: 20px!important;
    }
    .show-md-xs{
        background: transparent;
    }
    div#more_info {
        padding: 20px;
        background: #fff;
        border-radius: 5px;
    }
    .home_page_main_full .index_overflow .box-container_right{
        border-radius: 5px;
        background: #fff;
    }
    .show-md-xs.bg_mobile{
        display: block;
        border-radius: 5px;
        background: #fff;
        padding: 20px!important;
        margin-bottom: 10px;
        max-width: 92%;
        margin: 20px auto;
    }
    .home_page_main_full .index_overflow .box-container_right{
        max-width: 92%;
        margin: 20px auto;
    }
    div#more_info{
        max-width: 92%;
        margin: 20px auto;
    }
    .home_page_main_full .home_why_use_item h2 {
        display: block;
        color: #fff;
        font-size: 36px;
        line-height: 50px;
    }
    .home_why_use_item {
        width: 48%;
        padding: 20px 10px;
        border-radius: 5px;
        background: #fff;
        margin-bottom: 10px;
        box-shadow: 0 0 4px 1px rgba(0,0,0,0.2);
    }
    .bottom_links a{padding: 8px 10px}
    .home_page_main_full .home_why_use h2 {
        color: #e7a20f;
        display: block;
        color: #fff;
        font-size: 36px;
        line-height: 50px;
    }
    .home_page_main_full .home_why_use_item h2 {
        color: #000;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    .home_why_use_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
    }

    /*Container For Mobile*/
    .container {
        max-width: 100%!important;
        padding: 0px;
    }
    .container .content .home_page_main_full .box-top > div{
        padding: 0px 15px;
    }
    .container .content .home_page_main_full .box-top > div.home_why_use {
        padding-top: 40px;
        background: #fabb68;
        padding-bottom: 40px;
    }
}
`

function Home() {
    return (
        <div id="details">
            <style>{css}</style>
            <MetaTags>
                <title>Home - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="Home - Life Time Wills" />
            </MetaTags>

            {/*Added Sticky Promotion section and added container */}
            <div className="home_page_main_full">
                <div className="index_overflow clearfix">
                    <div className="box-top">
                    <div className="show-md-xs">
                        <h2>LifeTime Wills provides three Will services:</h2>
                        <div className="promo_item">
                            <div className="promo_item_img">
                                <img src="/images/services-personalised.svg" alt="" />
                            </div>
                            <div className="promo_item_txt">                    
                                <h4 className="pheader">Instant Will</h4>
                                <p className="pheader_content">£39<span> (£59 for mirrored Wills)</span></p>
                                <p>Initiate your Last Will and Testament online now and have it completed and returned
                                    within 48 hours.</p>
                                <ul>
                                    <li>
                                        <strong>FREE Guide</strong> - ‘The Essential Guide to Inheritance Tax’ (rrp £16.99).
                                    </li>
                                    <li>
                                        <strong>Advice You Can Trust</strong> - Our legal experts ensure your peace of mind
                                    </li>
                                    <li>
                                        <strong>FREE Consultation</strong> - Uncertain whether our service is right for you?
                                        Just <Link to="/contact.html" >contact us</Link>
                                    </li>
                                    <li>
                                        <strong>FREE Telephone/Email Support</strong> - Our customer care team are fast,
                                        friendly and knowledgeable
                                    </li>
                                </ul>
                                <div className="bottom_links">
                                    <Link to="/order-now.html">Order now</Link>
                                    <Link to="/Option1.html">Instant Will</Link>
                                </div>
                            </div>
                        </div>
                        <div className="promo_item">
                            <div className="promo_item_img">
                                <img src="/images/services-plus.svg" alt="" />
                            </div>
                            <div className="promo_item_txt"> 
                                <h4 className="pheader">Instant Will Plus</h4>
                                <p className="pheader_content">£69<span> (£99 for mirrored Wills)</span></p>
                                <p>As above plus your Will expertly proofread and edited by our Will writing professionals
                                    before dispatching by 1st Class Post <strong>within 48 hours</strong>.</p>
                                <ul>
                                    <li>
                                        <strong>Reviewed by Experts</strong> - Your Will thoroughly checked by Will writing
                                        professionals
                                    </li>
                                    <li>
                                        <strong>Advice You Can Trust</strong> - Our legal experts ensure your peace of mind
                                    </li>
                                    <li>
                                        <strong>FREE Consultation</strong> - Uncertain whether our service is right for you?
                                        Just <Link to="/contact.html">contact us</Link></li>
                                    <li>
                                        <strong>FREE Telephone/Email Support</strong> - Our customer care team are fast,
                                        friendly and knowledgeable
                                    </li>
                                    <li>
                                        <strong>FREE Postage</strong> - No extra charge for 1st class postage
                                    </li>
                                    <li>
                                        <strong>FREE Guide</strong> - ‘The Essential Guide to Inheritance Tax’ (rrp £16.99).
                                    </li>
                                    <li>
                                        <strong>50% Discount</strong> - Half price Will Storage
                                    </li>
                                </ul>
                                <div className="bottom_links">
                                    <Link to="/order-now.html">Order now</Link>
                                    <Link to="/Option2.html">Instant Will</Link>
                                </div>
                            </div>
                        </div>
                        <div className="promo_item">
                            <div className="promo_item_img">
                                <img src="/images/special-offer.svg" alt="" />
                            </div>
                            <div className="promo_item_txt"> 
                                <h4 className="pheader">Premier Service</h4>
                                <p className="pheader_content">£99<span> (£149 for mirrored Wills)</span></p>
                                <p>As Instant Will Plus product but with the addition of FREE lifetime secure storage of
                                    your Will.</p>
                                <ul>
                                    <li>
                                        <strong>Checked by Experts</strong> - Your Will reviewed by Will writing
                                        professionals
                                    </li>
                                    <li>
                                        <strong>Advice You Can Trust</strong> - Our legal experts ensure your peace of mind
                                    </li>
                                    <li>
                                        <strong>FREE Consultation</strong> - Uncertain whether our service is right for you?
                                        Just <Link to="/contact.html" >contact us</Link></li>
                                    <li>
                                        <strong>FREE Telephone/Email Support</strong> - Our customer care team are fast,
                                        friendly and knowledgeable
                                    </li>
                                    <li>
                                        <strong>FREE Postage</strong> - No extra charge for 1st class postage
                                    </li>
                                    <li>
                                        <strong>FREE Guide</strong> - ‘The Essential Guide to Inheritance Tax’ (rrp £16.99).
                                    </li>
                                    <li>
                                        <strong>FREE Storage</strong> - FREE lifetime Will Storage
                                    </li>
                                </ul>
                                <div className="bottom_links">
                                    <Link to="/order-now.html">Order now</Link>
                                    <Link to="/Option1.html">Instant Will</Link>
                                </div>
                            </div>
                        </div>
                        </div>
                        
                        <div className="hidden-md-xs">
                        <div id="index_title">
                            <h1 className="index_title">Will Writing - Professional &amp; Affordable Service <span>With Unlimited Updates</span></h1>
                        </div>
                        <ul className="sell">
                            <li>Complete your Will from just £39</li>
                            <li>Have your Will completed in under 3 minutes</li>
                            <li>Change your Will whenever you want for FREE</li>
                        </ul>
                        <ul className="sell2">
                            <li>Option for your partner to make a mirror Will</li>
                            <li>Have your Will reviewed by experts</li>
                            <li>Will storage provided from only £79</li>
                        </ul>
                        <p>
                            Will writing is something we all put off, yet it could be the most appreciated thing you ever
                            do for your family.
                        </p>
                        <p>
                            Your Last Will and Testament is a vital legal document, so it is important that your Will is
                            prepared correctly. Failure to do so could leave your benefactors to deal with complicated
                            and expensive issues. Last year over <strong>700,000 people died in the UK without a valid Will</strong> and a number of them had their estates passed to their partner’s parents,
                            their siblings and remote relatives against their wishes.
                        </p>
                        <p>
                            Since 2000 LifeTime Wills has made 1,000s of Wills and has dealt with every eventuality.
                            Customers say that they chose our Will service because we are the only UK Will service that
                            offers <strong>high quality, affordable Wills and FREE updates for life</strong>. Don’t go
                            through the repeated trouble and expense of updating your Will whenever your personal
                            circumstances change; this is the only Will writing resource you will ever need.
                        </p>
                        </div>
                        { /*Why Use Wills*/}
                        <div className="home_why_use">
                            <h2>Why Use Lifetime Wills?</h2>
                            <div className="home_why_use_items">
                                <div className="home_why_use_item">
                                    <img src="../images/selling-points-trofe.svg" alt="" />
                                    <Link to=""><h2>Experience &amp; Expertise</h2></Link>
                                    <p>We're the largest provider of will in the UK</p>
                                </div>
                                <div className="home_why_use_item">
                                    <img src="../images/selling-points-thumbs-up.svg" alt="" />
                                    <Link to=""><h2>Experience &amp; Expertise</h2></Link>
                                    <p>We're the largest provider of will in the UK</p>
                                </div> 
                                <div className="home_why_use_item">
                                    <img src="../images/selling-points-hand-shake.svg" alt="" />
                                    <Link to=""><h2>Experience &amp; Expertise</h2></Link>
                                    <p>We're the largest provider of will in the UK</p>
                                </div>
                                <div className="home_why_use_item">
                                    <img src="../images/selling-points-magnifier.svg" alt="" />
                                    <Link to=""><h2>Experience &amp; Expertise</h2></Link>
                                    <p>We're the largest provider of will in the UK</p>
                                </div>
                                <div className="home_why_use_item">
                                    <img src="../images/selling-points-customer-service.svg" alt="" />
                                    <Link to=""><h2>Experience &amp; Expertise</h2></Link>
                                    <p>We're the largest provider of will in the UK</p>
                                </div>
                                <div className="home_why_use_item">
                                    <img src="../images/selling-points-iso.svg" alt="" />
                                    <Link to=""><h2>Experience &amp; Expertise</h2></Link>
                                    <p>We're the largest provider of will in the UK</p>
                                </div>
                                <div className="home_why_use_item">
                                    <img src="../images/plus-points-guarantee.svg" alt="" />
                                    <Link to=""><h2>Experience &amp; Expertise</h2></Link>
                                    <p>We're the largest provider of will in the UK</p>
                                </div>
                                <div className="home_why_use_item">
                                    <img src="../images/selling-points-building.svg" alt="" />
                                    <Link to=""><h2>Experience &amp; Expertise</h2></Link>
                                    <p>We're the largest provider of will in the UK</p>
                                </div>
                            </div>
                        </div>
                        {/*Bottom Content*/}
                        <div className="hidden-md-xs">
                        <h2>LifeTime Wills provides three Will services:</h2>
                        <div className="promo_item">
                            <div className="promo_item_img">
                                <img src="/images/services-personalised.svg" alt="" />
                            </div>
                            <div className="promo_item_txt">                    
                                <h4 className="pheader">Instant Will</h4>
                                <p className="pheader_content">Just £39 (£59 for mirrored Wills)</p>
                                <p>Initiate your Last Will and Testament online now and have it completed and returned
                                    within 48 hours.</p>
                                <ul>
                                    <li>
                                        <strong>FREE Guide</strong> - ‘The Essential Guide to Inheritance Tax’ (rrp £16.99).
                                    </li>
                                    <li>
                                        <strong>Advice You Can Trust</strong> - Our legal experts ensure your peace of mind
                                    </li>
                                    <li>
                                        <strong>FREE Consultation</strong> - Uncertain whether our service is right for you?
                                        Just <Link to="/contact.html" >contact us</Link>
                                    </li>
                                    <li>
                                        <strong>FREE Telephone/Email Support</strong> - Our customer care team are fast,
                                        friendly and knowledgeable
                                    </li>
                                </ul>
                                <div className="bottom_links">
                                    <Link to="/order-now.html">Order now</Link>
                                    <Link to="/Option1.html">Instant Will</Link>
                                </div>
                            </div>
                        </div>
                        <div className="promo_item">
                            <div className="promo_item_img">
                                <img src="/images/services-plus.svg" alt="" />
                            </div>
                            <div className="promo_item_txt"> 
                                <h4 className="pheader">Instant Will Plus</h4>
                                <p className="pheader_content">Just £69 (£99 for mirrored Wills)</p>
                                <p>As above plus your Will expertly proofread and edited by our Will writing professionals
                                    before dispatching by 1st Class Post <strong>within 48 hours</strong>.</p>
                                <ul>
                                    <li>
                                        <strong>Reviewed by Experts</strong> - Your Will thoroughly checked by Will writing
                                        professionals
                                    </li>
                                    <li>
                                        <strong>Advice You Can Trust</strong> - Our legal experts ensure your peace of mind
                                    </li>
                                    <li>
                                        <strong>FREE Consultation</strong> - Uncertain whether our service is right for you?
                                        Just <Link to="/contact.html">contact us</Link></li>
                                    <li>
                                        <strong>FREE Telephone/Email Support</strong> - Our customer care team are fast,
                                        friendly and knowledgeable
                                    </li>
                                    <li>
                                        <strong>FREE Postage</strong> - No extra charge for 1st class postage
                                    </li>
                                    <li>
                                        <strong>FREE Guide</strong> - ‘The Essential Guide to Inheritance Tax’ (rrp £16.99).
                                    </li>
                                    <li>
                                        <strong>50% Discount</strong> - Half price Will Storage
                                    </li>
                                </ul>
                                <div className="bottom_links">
                                    <Link to="/order-now.html">Order now</Link>
                                    <Link to="/Option2.html">Instant Will</Link>
                                </div>
                            </div>
                        </div>
                        <div className="promo_item">
                            <div className="promo_item_img">
                                <img src="/images/special-offer.svg" alt="" />
                            </div>
                            <div className="promo_item_txt"> 
                                <h4 className="pheader">Premier Service</h4>
                                <p className="pheader_content">Just £99 (£149 for mirrored Wills)</p>
                                <p>As Instant Will Plus product but with the addition of FREE lifetime secure storage of
                                    your Will.</p>
                                <ul>
                                    <li>
                                        <strong>Checked by Experts</strong> - Your Will reviewed by Will writing
                                        professionals
                                    </li>
                                    <li>
                                        <strong>Advice You Can Trust</strong> - Our legal experts ensure your peace of mind
                                    </li>
                                    <li>
                                        <strong>FREE Consultation</strong> - Uncertain whether our service is right for you?
                                        Just <Link to="/contact.html" >contact us</Link></li>
                                    <li>
                                        <strong>FREE Telephone/Email Support</strong> - Our customer care team are fast,
                                        friendly and knowledgeable
                                    </li>
                                    <li>
                                        <strong>FREE Postage</strong> - No extra charge for 1st class postage
                                    </li>
                                    <li>
                                        <strong>FREE Guide</strong> - ‘The Essential Guide to Inheritance Tax’ (rrp £16.99).
                                    </li>
                                    <li>
                                        <strong>FREE Storage</strong> - FREE lifetime Will Storage
                                    </li>
                                </ul>
                                <div className="bottom_links">
                                    <Link to="/order-now.html">Order now</Link>
                                    <Link to="/Option1.html">Instant Will</Link>
                                </div>
                            </div>
                        </div>
                        </div>

                        {/*Top Section Cloned for Mobile View*/}
                        <div className="show-md-xs bg_mobile">
                            <div id="index_title">
                                <h1 className="index_title">Will Writing - Professional &amp; Affordable Service <span>With Unlimited Updates</span></h1>
                            </div>
                            <ul className="sell">
                                <li>Complete your Will from just £39</li>
                                <li>Have your Will completed in under 3 minutes</li>
                                <li>Change your Will whenever you want for FREE</li>
                            </ul>
                            <ul className="sell2">
                                <li>Option for your partner to make a mirror Will</li>
                                <li>Have your Will reviewed by experts</li>
                                <li>Will storage provided from only £79</li>
                            </ul>
                            <p>
                                Will writing is something we all put off, yet it could be the most appreciated thing you ever
                                do for your family.
                            </p>
                            <p>
                                Your Last Will and Testament is a vital legal document, so it is important that your Will is
                                prepared correctly. Failure to do so could leave your benefactors to deal with complicated
                                and expensive issues. Last year over <strong>700,000 people died in the UK without a valid Will</strong> and a number of them had their estates passed to their partner’s parents,
                                their siblings and remote relatives against their wishes.
                            </p>
                            <p>
                                Since 2000 LifeTime Wills has made 1,000s of Wills and has dealt with every eventuality.
                                Customers say that they chose our Will service because we are the only UK Will service that
                                offers <strong>high quality, affordable Wills and FREE updates for life</strong>. Don’t go
                                through the repeated trouble and expense of updating your Will whenever your personal
                                circumstances change; this is the only Will writing resource you will ever need.
                            </p>
                        </div>
                    </div>

                    <div className="box-container_right">
                        <div className="box">
                            <Link to="/option1.html" title="Wills option 1">
                                <img src="/images/option_box1.gif"
                                    alt="option 1"/>
                            </Link>
                            <p>Your Will completed, <br/>&amp; emailed in minutes</p>
                            <img src="/images/option_boxbase.gif" className="bot_img" alt=""/>
                        </div>

                        <div className="box">
                            <Link to="/option2.html" title="Wills option 2">
                                <img src="/images/option_box2.gif"
                                    alt="option 2"/>
                            </Link>
                            <p>Your Will reviewed<br/>by experts</p>
                            <img src="/images/option_boxbase.gif" className="bot_img" alt=""/>
                        </div>

                        <div className="box">
                            <Link to="/option3.html" title="Wills option 3">
                                <img src="/images/option_box3.gif" alt="option 3"/>
                            </Link>
                            <p>Your Will reviewed <br/>&amp; stored securely</p>
                            <img src="/images/option_boxbase.gif" className="bot_img" alt=""/>
                        </div>

                        <div className="box">
                            <Link to="/about-wills3.html" title="Storage Option">
                                <img src="/images/option_box4.gif" alt="storage"/>
                            </Link>
                            <p>LifeTime Storage</p>
                            <img src="/images/option_boxbase.gif" className="bot_img" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            
            {/*<div id="more_info" className="clearfix">
                <p><strong>Why is a Will important </strong>, click the ‘next’ button…</p>
                <Link to="/about-wills.html"  onMouseOut={() => chnageNextImage(0)} onMouseOver={() => chnageNextImage(1)} >
                    <img name="Image1" border="0" src="/images/next.gif" alt="next >"/></Link>
            </div>*/}
        </div>
    );
}

export default Home;