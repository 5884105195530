import React from "react";
import $ from 'jquery';
import 'bootstrap';
import {Link} from "react-router-dom";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import MetaTags from "react-meta-tags";

$(function () {
    $('[data-toggle="popover"]').popover();
})

$(document).ready(function (){
    $(".box-img-menu img").attr("src", "../images/picture2.png");
});

function changeImage(image){
    $(".box-img-menu img").attr("src", "../images/picture" + image + ".png");
}

class ExampleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "Mark Jones",
            fullNameOfPartner: "Christine Jones",
            currentAddress: "Beacons View West Side Road W1 3RT",
            addressProperty: "Beacons View West Side Road W1 3RT",
            approximateValue: "£100,000",
            popoverOpenHelpOne: false,
            popoverHeader: "",
            popoverMessage: "",
            popOverView: {"first": true, "second": true, "third": true}
        };
        this.togglePopoverHelpOne = this.togglePopoverHelpOne.bind(this);
    }

    togglePopoverHelpOne() {
        this.setState({ popoverOpenHelpOne: !this.state.popoverOpenHelpOne })
    }

    popOver(primo, secondo){
        this.setState({ popoverHeader: primo })
        this.setState({ popoverMessage: secondo })
    }

    // close all popover
    closeAllPopover(){
        this.setState({ popoverOpenHelpOne: false })
    }

    render () {
        const { popoverOpenHelpOne } = this.state;
        return (
            <div id="details" onClick={this.closeAllPopover.bind(this)}>
                <MetaTags>
                    <title>Example form - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="Example form - Life Time Wills" />
                </MetaTags>
                <h2>Example Forms</h2>
                <img src="images/title_example.gif" alt=""/>
                <p>Below is an example of the simple, easy-to-use form that you will complete to create your Will.
                    Click on <font color="#6699CC">[ help ]</font> for an explanation of how to answer the relevant
                    question.</p>
                <form name="form1" method="post" action="">
                    <table border="0" align="center" cellPadding="5" cellSpacing="5" className="example-table">
                        <tbody>
                        <tr>
                            <td width="150" className="backcell">Full name:</td>
                            <td width="15" className="backcell"><img src="images/right.gif" alt=">"/></td>
                            <td width="200" bgcolor="#FFF1DD"><input name="textfield" type="text" readOnly value={this.state.fullName}
                                                                     size="28" maxLength="30" className="fld form-control"/></td>
                            <td width="50">
                                <Popover
                                    placement="right"
                                    isOpen={popoverOpenHelpOne}
                                    target="popover-open-help-one"
                                    toggle={this.togglePopoverHelpOne}>
                                    <PopoverHeader>{this.state.popoverHeader}</PopoverHeader>
                                    <PopoverBody>
                                        <p>{this.state.popoverMessage}</p>
                                    </PopoverBody>
                                </Popover>
                                <a  href="#/" onMouseOut={this.closeAllPopover.bind(this)}
                                   onClick={() => this.popOver("Help Info", "Please enter your full name as you are now known.")}
                                   alt="Please enter your full name as you are now known."
                                   id="popover-open-help-one"
                                    className="popoverBox">[
                                    help ]
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className="backcell">Full name of partner:</td>
                            <td className="backcell"><img src="images/right.gif" alt=">"/></td>
                            <td bgcolor="#FFF1DD"><input name="textfield2" type="text" className="fld form-control"
                                                         readOnly value={this.state.fullNameOfPartner} size="28" maxLength="30"/></td>
                            <td>

                                <a href="#/" onClick={() => this.popOver("Help Info", "Please enter your full name of your partner / husband or wife")}
                                   id="popover-open-help-one"
                                   onMouseOut={this.closeAllPopover.bind(this)}
                                   alt="Please enter your full name of your partner / husband or wife"
                                   className="popoverBox">[
                                    help ]
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className="backcell">Your current address:</td>
                            <td className="backcell"><img src="images/right.gif" alt=">"/></td>
                            <td bgcolor="#FFF1DD">
			                <textarea name="textarea" cols="30" rows="3" readOnly className="fld  form-control" placeholder={this.state.currentAddress}></textarea>
                            </td>
                            <td>
                                <a href="#/" onClick={() => this.popOver("Help Info", "Please enter your current address at which you now live")}
                                   onMouseOut={this.closeAllPopover.bind(this)} id="popover-open-help-one"
                                   alt="Please enter your current address at which you now live"
                                   className="popoverBox"
                                >[
                                    help ]
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className="backcell"><strong>Gifts of Property</strong></td>
                            <td className="backcell">&nbsp;</td>
                            <td bgcolor="#FFF1DD">&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="backcell">Address of property:</td>
                            <td className="backcell"><img src="images/right.gif" alt=">"/></td>
                            <td bgcolor="#FFF1DD">
                                <textarea name="textarea" cols="30" rows="3"  readOnly className="fld  form-control" placeholder={this.state.currentAddress}></textarea>
                            </td>
                            <td>
                                <a href="#/" onClick={() => this.popOver("Help Info", "Please enter the address of the property you wish to leave in your will.")}
                                   onMouseOut={this.closeAllPopover.bind(this)} id="popover-open-help-one"
                                   alt="Please enter the address of the property you wish to leave in your will."
                                   className="popoverBox"
                                >[ help ]
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className="backcell">Approximate value:</td>
                            <td className="backcell">&nbsp;</td>
                            <td bgcolor="#FFF1DD"><input name="textfield3" type="text" readOnly value={this.state.approximateValue} size="28"
                                                         maxLength="30" className="fld form-control"/></td>
                            <td>
                                <a href="#/" onClick={() => this.popOver("Help Info", "Please enter the approximate value of the property you entered in the previous question.")}
                                   onMouseOut={this.closeAllPopover.bind(this)} id="popover-open-help-one"
                                   alt="Please enter the approximate value of the property you entered in the previous question."
                                   className="popoverBox"
                                >[ help ]
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
                <p>To compare the prices and services of other online Will websites, click the ' next ' button…</p>
                <div id="more_info" classNAme="clearfix">
                    <Link to="order-now.html" className="fleft"><img
                        src="/images/order.gif" name="Image13" border="0" alt="order now"/></Link>
                    <Link  onClick={() => changeImage(3)} to="compare-prices1.html"><img
                        src="/images/next.gif" name="Image12" className="Image12" border="0" alt="next >"/></Link>
                </div>
            </div>
        );
    }
}

export default ExampleForm;