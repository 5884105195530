import React from "react";
import MetaTags from "react-meta-tags";

function About() {
    return (
        <div id="details" className="faq_main">
            <MetaTags>
                <title>FAQ - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="FAQ - Life Time Wills" />
            </MetaTags>
            
            <h1 className="page_title">Lifetime Wills Frequently Asked Questions</h1>
            {/* <img src="/images/title_faqs.gif" alt="" /> */}
                <table width="100%" border="0" cellSpacing="2" cellPadding="4">
                    <tbody>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a2" className="quest">What happens if I don't make a
                            Will?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a2b" className="quest">In which countries will my
                            Will be valid? </a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a3" className="quest">Can a letter be as legal as a
                            Will?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a4" className="quest">Can I change a Will?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a5" className="quest">Can I cancel my Will?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a6" className="quest">Does marriage or divorce
                            affect my Will?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a7" className="quest">Can a Will be changed after my
                            death?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a8" className="quest">What happens if I leave
                            someone out of my Will?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a9" className="quest">What are Executors?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a10" className="quest">What are Trustees?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a11" className="quest">How many Executors can I
                            appoint?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a12" className="quest">Whom should I appoint as
                            Executors?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a13" className="quest">Do Executors get paid?</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a14" className="quest">What do Executors do?</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a15" className="quest">Should I appoint
                            Guardians?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a16" className="quest">What happens to property in
                            joint names?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a17" className="quest">What about Inheritance
                            Tax?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a18" className="quest">What is a gift made "free of
                            tax"?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a19" className="quest">After I have signed my Will
                            can I leave with it a list of gifts of personal belongings?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a20" className="quest">What does it mean if I give
                            someone a "life interest" in my Estate?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a21" className="quest">What should I do with my
                            Will?</a></td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a22" className="quest">What is a "Living Will"?</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="backcell"><a href="/faq.html#a23" className="quest">What is "Residue"?</a></td>
                    </tr>
                    </tbody>
                </table>
                <table width="100%" border="0" cellSpacing="2" cellPadding="4">
                    <tbody>
                    <tr>
                        <td height="5"></td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a1" href="/faq.html#a1" className="quest">What is a Will?</a></strong><br/>
                                A Will is a just a written record that says who you want your money and possessions to go to
                                when you die. As it is a very important document the law specifies what will be recognised
                                as a Will.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a2" href="/faq.html#a2" className="quest">What happens if I don't make a Will?</a></strong><br/>
                                Your property will be divided according to certain legal rules (the "intestacy rules"). Some
                                of them may surprise you. For example, the share your husband or wife gets may not be enough
                                to give him or her outright ownership of your home.
                            </p>
                            <p> Also, if you are not married but have been living with someone, the person you are
                                living with will probably get nothing.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a2b" href="/faq.html#a2b" className="quest">In which countries will my Will be
                            valid?</a><br/>
                            </strong>A Will made by Lifetime Wills will only be valid in England and Wales.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a3" href="/faq.html#a3" className="quest">Can a letter be as legal as a Will?</a></strong><br/>
                                Yes, a letter could be a legal Will. However, it would need to be witnessed and could well
                                be contested if the wording is ambiguous.
                            </p>
                            <p> It is always better to have your Will professionally written. </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p>
                                <strong><a name="a4" href="/faq.html#a4" className="quest">Can I change a Will?</a></strong><br/>
                                Yes, but only by signing a document called a Codicil. This must be prepared, signed and
                                executed in a particular way. You do not need a Codicil if you or any person named in your
                                Will changes their address.
                            </p>
                            <p>A Codicil is useful for minor changes to your Will. If you wish to make major changes it
                                is often preferable to write a new Will.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell"><p><strong><a name="a5" href="/faq.html#a5" className="quest">Can I cancel my
                            Will?</a></strong><br/>
                            You can cancel your Will by making a new Will, or by tearing it up or burning it.</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell"><p><strong><a name="a6" href="/faq.html#a6" className="quest">Does marriage or divorce
                            affect my Will?</a></strong>
                            <br/>
                                A Will is almost always automatically voided if you get married after you have made it.
                                If you get divorced after you have made it, any provisions in favour of your ex-spouse
                                will be cancelled unless the Will states otherwise.</p>
                            <p>It is thus essential that you consider writing a new Will if there are major changes to
                                your marital circumstances.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell"><p><strong><a name="a7" href="/faq.html#a7" className="quest">Can a Will be changed after my
                            death?</a></strong><br/>
                            No, at least, not normally. However, there may be instances where the Court may make a
                            judgement.</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a8" href="/faq.html#a8" className="quest">What happens if I leave
                                someone out of my Will?</a></strong><br/>
                                If you have not properly provided for any of your dependants who are unable to maintain
                                themselves, or if you have not been fair to your spouse (or an ex-spouse who has not
                                remarried), the Court can alter your Will.
                            </p>
                            <p>Any reasons for leaving someone out should therefore be given in your Will or in a
                                separate letter which can be referred to in your Will. The Court will then consider
                                these reasons although it will not be bound by them.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell"><p><strong><a name="a9" href="/faq.html#a9" className="quest">What are
                            Executors?</a></strong><br/>Executors are simply the people appointed in your Will to carry
                            out your instructions.</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a10" href="/faq.html#a10" className="quest">What are
                                Trustees?</a></strong><br/>Trustees are the people appointed in your Will to look after your
                                property or interests until, say, your children are old enough to inherit. Executors and
                                Trustees are often (but not necessarily) the same people.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell"><p><strong><a name="a11" href="/faq.html#a11" className="quest">How many Executors can I
                            appoint?</a></strong><br/>
                            You can reasonably appoint up to four Executors (or as many as you like), but we recommend
                            you should normally appoint two.
                        </p>
                            <p>You can appoint back-up Executors in case your first choice decides not to take the
                                position or predeceases you. </p></td>
                    </tr>
                    <tr>
                        <td className="faqcell"><p><strong><a name="a12" href="/faq.html#a12" className="quest">Whom should I appoint as
                            Executors?</a></strong><br/>
                            Any adult, solicitor or bank may be appointed as an Executor. One could be the person who is
                            going to receive the biggest share of your estate, such as your spouse. Relatives and close
                            family friends can also be Executors.</p>
                            <p>It's easy to appoint another Executor later if you want to do so by means of a Codicil.
                                If you appoint a professional Trustee, they will charge for their service. For more on
                                this, see our section on Trust Corporations below. Trust Corporations, if chosen, are
                                generally much more cost-effective than a bank or solicitor.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a13" href="/faq.html#a13" className="quest">Do Executors get
                            paid?</a></strong><br/>
                            Only if the Will says so. Most Wills provide that solicitors or banks should be paid their
                            normal fees. They would not act otherwise.
                            </p>
                            <p>A solicitor would normally charge around 2% to 3% of the value of the Estate. A bank's
                                fees would be considerably higher. We can generally recommend a Trust Corporation as the
                                least expensive option.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a14"  href="/faq.html#a14" className="quest">What do Executors
                                do?</a></strong><br/>
                                Executors bring together all your assets, pay your debts and any gifts of money, transfer
                                any gifts or personal belongings and deal with the remainder - your Residuary Estate - in
                                accordance with your Will.
                            </p>
                            <p>Often non-professional Executors will ask a solicitor or other professional organisation
                                such as a Trust Corporation to do some or all of the work for them.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a15" href="/faq.html#a15" className="quest">Should I appoint
                                Guardians?</a></strong><br/>If you have children under the age of 18 who may be left with no
                                parents, we strongly recommend you should appoint a suitable Guardian or Guardians.
                            </p>
                                <p>The appointment of Guardianship allows you to decide who should be responsible for your
                                children's welfare. You should also give consideration as to the age of the Guardians,
                                consider their current relationship with your children or whether they have the same views
                                on education, and so on.
                                It is quite usual for Guardians to also be Executors.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a16"  href="/faq.html#a16" className="quest">What happens to property in
                            joint names?</a></strong><br/>
                            Husbands and wives are usually, but not always, "joint tenants". This means that when one of
                            them dies the other one automatically becomes the owner of the property. It also means that
                            a joint tenant cannot make a gift in a Will of his or her share of the property.</p>
                            <p>Business partners are usually, but not always, "tenants in common". This means that when
                                one of them dies, the interest in the property forms part of the Estate. It also means
                                that a "tenant in common" can make a gift in a Will of his or her share of the
                                property. </p>
                            <p>A joint tenancy can easily be converted into a tenancy in common by one of the co-owners
                                giving a written notice to the other saying that the joint tenancy has been "severed"
                                and that they are now tenants in common. Such a notice should be placed with the deeds.
                                If you don't know whether you are joint tenants or tenants in common, you should consult
                                the solicitor employed in the purchase. </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong>
                                <a name="a17" href="/faq.html#a17" className="quest">What about Inheritance
                                Tax?</a></strong><br/>
                                If the value of your Estate is more than £300,000 after payment of your debts and any gifts
                                to your husband or wife or to charity, Inheritance Tax (IHT) may be payable at 40% on the
                                value over this amount.
                            </p>
                            <p>We can advise you on ways of reducing or removing the impact of Inheritance Tax. </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p>
                                <strong><a name="a18" href="/faq.html#a18" className="quest">What is a gift made "free of
                                tax"?</a></strong><br/>
                                A gift is free of tax when any Inheritance Tax (IHT), if it is payable, is to be paid out of
                                your Residuary Estate and not to be taken out of the gift or otherwise paid by the
                                recipient.
                            </p>
                            <p>With our Wills, all gifts, except of the Residue, are free of IHT. Regardless, all gifts
                                to charities are exempt from IHT.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell"><p><strong><a name="a19"  href="/faq.html#a19" className="quest">After I have signed my Will
                            can I leave with it a list of gifts of personal belongings?</a></strong><br/>
                            You can do this if you have said so in your Will but do not attach the list to your Will. It
                            is better to include each individual gift as part of the Will.</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p><strong><a name="a20" href="/faq.html#a20" className="quest">What does it mean if I give
                                someone a "life interest" in my Estate?</a></strong><br/>
                                If, say, you wish to ensure that your partner is adequately provided for but feel you have a
                                duty towards, say, children of an earlier marriage, then you may wish to consider giving
                                your partner a "life interest" in your Estate. This restricts the partner's inheritance to
                                the income only of your monetary assets and the use of your home. On the death of your
                                partner the capital will pass to whoever you have specified in your Will.
                            </p>
                            <p>You should, however, bear in mind that unless the Estate is fairly large, the income may
                                be insufficient to support your partner. The duties of the Executors and Trustees will
                                also be more onerous.
                                When considering a gift of a life interest, it is very important to remember that the
                                recipient does not own the property in your Residuary Estate and cannot dispose of it in
                                his or her Will. It is important to remember that the prime duty of the Trustees is to
                                keep a fair balance between income for the person getting a life interest and capital
                                growth for those who will be ultimately entitled to your Residuary Estate.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell"><p><strong><a name="a21" href="/faq.html#a21" className="quest">What should I do with my
                            Will?</a></strong><br/>
                            You should leave it in a safe place. Your Executors will need the original. We can arrange
                            for your Will to be stored in our Will Bank.</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell"><p><strong><a name="a22" href="/faq.html#a22" className="quest">What is a "Living Will"?</a></strong><br/>
                            A "Living Will" (also called an advanced directive) is a set of instructions issued in
                            advance to the medical practitioners who may be involved in looking after you in the future.
                        </p>
                            <p>People making a Living Will state that they do not wish their lives to be artificially
                                prolonged when suffering from a terminal illness or other degenerative conditions.</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="faqcell">
                            <p>
                                <strong>
                                    <a name="a23" href="/faq.html#a23" className="quest">What is "Residue"?</a>
                                </strong>
                                <br/>
                            The residue is everything that remains after specific bequests (if any) and after payment of
                            all debts, taxes and expenses.
                            </p>
                            <p>There is no need to itemise the residue, you can simply decide what proportion each
                                beneficiary is to receive, if you are naming more than one. You may wish to leave the
                                residue to your spouse and then for it to pass on to your children.
                                If perhaps you do not wish your children to benefit in equal shares you can state what
                                proportion each child is to receive. </p>
                            <p>If you are single, or do not have any children you may wish to leave the residue to other
                                named beneficiaries.</p></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
}

export default About;