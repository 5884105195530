import React from "react";
import $ from "jquery";
import axios from 'axios';

$(document).ready(function(){
    axios.get('/sitemap.xml', {
        "Content-Type": "application/xml; charset=utf-8"
    })
        .then(res => {
            $("#show-xml").css({"width": "800px", "min-height": "1200px", 'border': 'none'}).text(res.data);
        }).catch(error => {
       alert("Ops! Sometehing went wrong. " + error);
    });
});

function SiteMap() {
    return (
        <div id="details">
            <textarea id="show-xml">
            </textarea>
        </div>
        );
}

export default SiteMap;