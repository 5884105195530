import React from "react";
import $ from 'jquery';
import 'bootstrap';
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";

$(function () {
    $('[data-toggle="popover"]').popover();
})

$(document).ready(function (){
    $(".box-img-menu img").attr("src", "../images/picture2.png");
});

function changeImage(image){
    $(".box-img-menu img").attr("src", "../images/picture" + image + ".png");
}

function chnageNextImage(image){
    if(image){
        $("img[name='Image12']").attr("src", "/images/next_over.gif");
    } else {
        $("img[name='Image12']").attr("src", "/images/next.gif");
    }
}

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "Mark Jones",
            fullNameOfPartner: "Christine Jones",
            currentAddress: "Beacons View West Side Road W1 3RT",
            addressProperty: "Beacons View West Side Road W1 3RT",
            approximateValue: "£100,000"
        };
    }

    render () {
        return (
            <div id="details">
                <MetaTags>
                    <title>Step 2 - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="Step 2 - Life Time Wills" />
                </MetaTags>
                <h1 className="page_title">Step 2 - Submit Your Details</h1>
                {/*<img src="/images/title_step2.gif" alt="" />*/}
                <p><strong>Submit your details </strong>with a single click . After completing the questionnaire
                    your details have to be submitted to produce your Will. Once completed, your documentation will
                    be emailed or posted to you.
                </p>
                <img src="/images/submit_pic.gif" alt="easy submit process displayed" className="submit_img" />
                <div >
                    <p><strong>To view the next step</strong> click the 'next' button…</p>
                    <Link onMouseOut={() => chnageNextImage(0)} onMouseOver={() => chnageNextImage(1)}  onClick={() => changeImage(9)} to="step3.html">
                        <img
                        src="/images/next.gif" name="Image12" className="Image12" border="0" alt="next >"/>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Step2;