import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import MetaTags from "react-meta-tags";


function changeImage(image){
    $(".box-img-menu img").attr("src", "../images/picture" + image + ".png");
}

function chnageNextImage(image){
    if(image){
        $("img[name='Image1']").attr("src", "/images/next_over.gif");
    } else {
        $("img[name='Image1']").attr("src", "/images/next.gif");
    }
}

function About() {
    return (
        <div id="details">
            <MetaTags>
                <title>About us two - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="About us two - Life Time Wills" />
            </MetaTags>
            <h1 className="page_title">When Do I Need to Prepare a New Will?</h1>
            <p>You may need to revise your Will for any number of reasons. Common occasions for reviewing a Will
                include: </p>
            <ul className="widthSet">
                <li><strong>Changes in the family</strong> - a baby is born, a child becomes 18 (or perhaps some
                    significant later age), or there is a death.
                </li>
                <li><strong>Marriage</strong> - automatically revokes a previous Will
                    unless your Will expressly states that it is made in contemplation of
                    that forthcoming marriage. It is always safer to prepare a new Will
                    upon marrying.
                </li>
                <li><strong>Divorce</strong> - unlike marriage, a divorce does not revoke
                    a previous Will. But if your former spouse is named as a beneficiary,
                    then upon divorce he or she will cease to be a beneficiary or receive
                    a gift unless your Will expressly provides that the gift should still
                    take effect if you divorce. If your former spouse is named executor,
                    then upon divorce he or she will no longer be allowed to act as executor
                    or obtain probate of your Will. It is best to make a new Will whenever
                    you get divorced.
                </li>
                <li><strong>Separation</strong> - does not have the effect on a Will which
                    a divorce has so it is best to review the Will as soon as separation
                    occurs.
                </li>
                <li><strong>Change in financial circumstances</strong> - you may have
                    recently acquired assets which you would like to give to particular
                    beneficiaries, or perhaps due to hard times your estate may have become
                    insufficient to provide for the legacies you have made.
                </li>
                <li><strong>Changes in taxation</strong> - if your estate is large enough
                    to attract tax, new taxes or reliefs or changes in the rates may call
                    for charges in your Will.
                </li>
                <li><strong>Going to live abroad</strong> - it is normally desirable to
                    make a Will in the country where you reside to simplify the administration
                    of your estate. It may also be helpful if you need to establish a change
                    of domicile. Contact us for advice.
                </li>
            </ul>
            <p>In any case, it is a good idea to review your Will every year, so that it is always up to date.</p>
            <br className="clear" />
            <div id="more_info" className="clearfix">
                <p><strong>Where should I keep my Will?</strong> Click on the 'next' button…</p>
                <Link onMouseOut={() => chnageNextImage(0)} onMouseOver={() => chnageNextImage(1)}  onClick={() => changeImage(2)} to="/about-wills3.html" >
                    <img name="Image1" border="0" src="/images/next.gif" alt="next >" />
                </Link>
            </div>
        </div>
        );
}

export default About;