import React from "react";
import MetaTags from "react-meta-tags";

function AboutUs() {
    return (
        <div id="details">
            <MetaTags>
                <title>About us - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="About us - Life Time Wills" />
            </MetaTags>
            <h1 className="page_title">Lifetime Wills About Us</h1>
            <div>
                {/*<img src="/images/title_about.gif" alt=""/>*/}
                    <p>"We are a group of Will writing and probate experts committed to making the processing of downloadable legal
                        information as quick, simple and affordable as possible. We designed LifeTime-Wills.com to make the process of
                        making a legally binding Will as simple and affordable as we could.</p>
                    <p>LifeTime Wills UK provides a quality online service. Unlike many cheaper or inferior alternatives, every process
                        and document has been written and approved by our independent panel solicitors whom have decades of experience in family law.</p>
                    <p>Every process and document has been double-checked so our clients can be confident that any advice or documentation
                        we dispense is expedient, appropriate and legally correct.</p>
                    <p>LifeTime Wills UK has been trading online since 2000 and is now well established as a respected provider of downloadable
                        legal documentation. For example, our sister company quickie-divorce.com is currently providing 1 in 8 of all home-based
                        divorces processed in the UK. </p>
                    <p>We are dedicated to providing the simplest, most easy-to-follow and affordable legal products available online."</p>
                    <p>LifeTime Wills UK</p>
            </div>
        </div>
        );
}

export default AboutUs;