import React from "react";
import $ from 'jquery';
import 'bootstrap';
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";

$(function () {
    $('[data-toggle="popover"]').popover();
})

$(document).ready(function (){
    $(".box-img-menu img").attr("src", "../images/picture2.png");
});

function changeImage(image){
    $(".box-img-menu img").attr("src", "../images/picture" + image + ".png");
}

function chnageNextImage(image){
    if(image){
        $("img[name='Image12']").attr("src", "/images/next_over.gif");
    } else {
        $("img[name='Image12']").attr("src", "/images/next.gif");
    }
}

class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "Mark Jones",
            fullNameOfPartner: "Christine Jones",
            currentAddress: "Beacons View West Side Road W1 3RT",
            addressProperty: "Beacons View West Side Road W1 3RT",
            approximateValue: "£100,000"
        };
    }

    render () {
        return (
            <div id="details" className="step-three-cont">
                <MetaTags>
                    <title>Step 3 - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="Step 3 - Life Time Wills" />
                </MetaTags>
                <h1 className="page_title text-left">Step 3 - Sign Your Will</h1>
                {/* <img src="images/title_step3.gif" alt="" /> */}
                <p><strong>Sign your Will</strong>. When you receive your Will all you have to do is sign it.</p>
                <div className="row text-center">
                    <div className="col-md-6">
                        <img src="/images/will_sign.gif" alt="example of signature form" className="exampleSig" />

                    </div>
                    <div className="col-md-6 text-center">
                        <img src="/images/sign1.jpg" alt="example of will signing" className="exampleSig2" />
                        <br />
                        <Link to="/example-will.html" className="ext-center">Example Will</Link>
                    </div>
                </div>
                <div>
                    <p><strong>To view the next step</strong> click the 'next' button…</p>
                    <Link  onMouseOut={() => chnageNextImage(0)} onMouseOver={() => chnageNextImage(1)} onClick={() => changeImage(2)} to="order-now.html">
                        <img
                        src="/images/next.gif" name="Image12" className="Image12" border="0" alt="next >"/>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Step3;