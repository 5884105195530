import React from "react";
import $ from 'jquery';
import 'bootstrap';
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";

$(function () {
    $('[data-toggle="popover"]').popover();
})

$(document).ready(function (){
    $(".box-img-menu img").attr("src", "../images/picture2.png");
});

class ExampleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "Mark Jones",
            fullNameOfPartner: "Christine Jones",
            currentAddress: "Beacons View West Side Road W1 3RT",
            addressProperty: "Beacons View West Side Road W1 3RT",
            approximateValue: "£100,000"
        };
    }

    render () {
        return (
            <div id="details">
                <MetaTags>
                    <title>Example Will - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="Example Will - Life Time Wills" />
                </MetaTags>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                    <tr>
                        <td colSpan="2"><img src="images/title_example_will.gif"  alt="title example" /></td>
                    </tr>
                    <tr>
                        <td height="10" colSpan="2"></td>
                    </tr>
                    <tr>
                        <td align="center" colSpan="2"><img src="images/will_img.jpg" alt="will img" /></td>
                    </tr>
                    <tr>
                        <td height="10" colSpan="2"></td>
                    </tr>
                    <tr>
                        <p>Start making your Will by clicking on the 'order now' button...</p>
                        <div id="more_info" className="clearfix">
                            <Link to="/order-now.html" className="fleft">
                                <img src="/images/order.gif" name="Image13" border="0" alt="order now" />
                            </Link>
                        </div>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ExampleForm;