import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import MetaTags from "react-meta-tags";

function changeImage(image){
    $(".box-img-menu img").attr("src", "../images/picture" + image + ".png");
}

function chnageNextImage(image){
    if(image){
        $("img[name='Image1']").attr("src", "/images/next_over.gif");
    } else {
        $("img[name='Image1']").attr("src", "/images/next.gif");
    }
}

function About() {
    return (
            <div id="details">
                <MetaTags>
                    <title>About - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="About - Life Time Wills" />
                </MetaTags>
                <h1 className="page_title" >Why is a Will Important?</h1>
                <div className="abou_us_col">
                    {/*<img src="/images/title_will.gif" alt="title will" /> */}
                    <div className="box-float clearfix no_top">
                        <div id="left_col">
                            <p>It is sensible to make or change your Will after any significant changes in your life. For
                                example,
                                <strong>you should make a new Will if you are getting a divorce</strong>. Without a valid
                                Will
                                you cannot control who will inherit your property after your death. Should you die intestate
                                (without a Will), your property will be distributed according to law, which is likely to be
                                inconsistent
                                with your personal wishes. In some cases your estate may go to the Crown instead of the
                                people you want to benefit.
                            </p>
                            <p>By making a Will you can determine precisely who
                                will inherit your property and let your loved ones know that you
                                have considered their needs. Equally important, you can determine
                                who will administer your estate and who will act as guardian for
                                any minor children you have, if they are left without a surviving
                                parent.
                            </p>
                            <p>You can also use your Will to express your preferences
                                for burial or cremation and for donating organs or your entire body
                                for medical purposes. In addition, making a Will gives you the opportunity
                                of reducing your Inheritance Tax liability. This is particularly
                                important if you have substantial assets or a highly valued home.
                            </p>
                        </div>
                        <div id="right_col">
                            <p>When you die leaving
                                a valid Will that appoints one or two executors who are still living
                                at the time of your death, legal ownership of all your property
                                passes automatcally to those executors. In order to prove that they
                                have the right to deal with your property, they must apply for a
                                legal document confirming their right to do so from the Probate
                                Registry. This process is called obtaining probate.
                            </p>
                            <img src="/images/pic_6.jpg" className="mid img-borded" alt="married couple" title="Married Couple"/>
                            <p>“The LifeTime Wills format made it possible for us to make provision for every
                                conceivable eventuality”
                            </p>
                            <p className="center"></p>
                            <Link to="/testimonials.html" className="bigger-title">More testimonials »</Link>
                        </div>
                    </div>
                </div>
                <div id="more_info" className="clearfix no_margin">
                    <p><strong>Why should I make a Will?</strong> Click on the 'next' button…</p>
                    <Link  onClick={() => changeImage(4)} onMouseOut={() => chnageNextImage(0)} onMouseOver={() => chnageNextImage(1)} to="/about-wills1.html" >
                        <img name="Image1" border="0" src="/images/next.gif" alt="next >" title="Next >"/>
                    </Link>
                </div>
            </div>
        );
}

export default About;