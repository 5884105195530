import React from "react";
import {Link} from "react-router-dom";

function Footer(props) {
    return (
        <footer className="footer text-center" id="footer">
            <div className="foot_contact_us">
                <p>Confused About Will? Call <span>01656 508 262</span> or drop us a message.</p>
                <Link className="" to="/contact.html">Contact Us</Link>
            </div>
            <div className="foot_menus">
                <div className="foot_logo">
                    <Link to="/">
                        <img src={props.footers.imageLogo2Link} alt="Lifetime Wills" border="0"/>
                    </Link>
                </div>
                <div className="foot_links">
                    {/*<h4>About Wills</h4>*/}                  
                    <Link to="/Option1.html">Instant Will</Link>
                    <Link to="/Option2.html">Instant Will plus</Link>
                    <Link to="/Option3.html">Premier service</Link>
                    <Link to="/about-wills3.html" >Storage option</Link>
                    <Link to="/testimonials.html">Testimonials</Link>
                    <Link to="/site-map.html">Sitemap</Link>
                </div>
            </div>
            <div className="foot_Copy">
                <p className="text-footer-mid">UKDS, © 2022 - All Rights Reserved</p>
            </div>            
            <script src="http://www.google-analytics.com/urchin.js" type="text/javascript">
            </script>
            <script type="text/javascript">
                _uacct = "UA-2239206-1";
                urchinTracker();
            </script>
        </footer>
    );
}

export default Footer;