import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Footer, Header, MenuBtn} from "./components";
import $ from "jquery";
import About from "./containers/About/About";
import AboutUs from "./containers/About/About-us";
import Contact from "./containers/Contact/Contact";
import Home from "./containers/Home/Home";
import Option1 from "./containers/InstantWill/Option1";
import Option2 from "./containers/InstantWillPlus/Option2";
import ExampleForm from "./containers/ExampleForm/ExampleForm";
import AboutWills1 from "./containers/AboutWills/About-Wills1";
import Option3 from "./containers/PremierService/Option3";
import AboutWills2 from "./containers/AboutWills/About-Wills2";
import AboutWills3 from "./containers/AboutWills/About-Wills3";
import Testimonials from "./containers/Testimonials/Testimonials";
import Faq from "./containers/Faq/Faq";
import OrderNow from "./containers/OrderNow/OrderNow";
import ComparePrices from "./containers/ComparePrices/ComparePrices";
import Disclaimer from "./containers/Disclaimer/Disclaimer";
import Terms from "./containers/Terms/Terms";
import Step1 from "./containers/Steps/Step1";
import Step2 from "./containers/Steps/Step2";
import Step3 from "./containers/Steps/Step3";
import ExampleWill from "./containers/ExampleForm/ExampleWill";
import SiteMap from "./containers/SiteMap/SiteMap";

const headerSettings = {
    imageLogoLink: "/images/will_logo.gif",
    strapLineGifLink: "/images/space.gif",
    testimonialTopLink: "http://www.lifetime-wills.com",
    headerBannerLink: "/images/banner-image.png",
    headerContactUS: "/images/call-us-phone.gif",
    testimonialTopImageLink: "/images/will_logo2.jpg"
}

const footerSettings = {
    imageLogo2Link: "/images/will_logo.gif",
}

const topNav = [
    {
        steplink: "/step1.html",
        stepImage: "images/nav_step1.gif",
        bannerNewLink: "images/banner1.gif"
    },
    {
        steplink: "/step2.html",
        stepImage: "images/nav_step2.gif",
        bannerNewLink: "images/banner2.gif"
    },
    {
        steplink: "/step3.html",
        stepImage: "images/nav_step3.gif",
        bannerNewLink: "images/banner3.gif"
    },
]

$(document).ready(function () {

    // For Mobile Menu Top
    $(".menu-toggle").click(function(){
        $(".menu-toggle").toggleClass("menu-toggeled");
        $("#submenu_toggle").toggleClass("open");
    });

    // $(".header_main .sidebar li a").click(function(){
    //     $(".header_main .sidebar li a").removeClass("active");
    //     $(this).addClass("active");
    // });

    // $(".home_page_main_full").parents(".content").addClass('home');

    $(".header_main .sidebar ul li a").click(function(){
        $(".header_main .sidebar ul li a").removeClass("active");
        $(this).addClass("active");
    });

    $("#submenu_toggle .head_submenu ul li a").click(function(){
        $(".menu-toggle").removeClass("menu-toggeled");
        $("#submenu_toggle").removeClass("open");
        $("#submenu_toggle .head_submenu ul li a").removeClass("active");
        $(this).addClass("active");

        // if ($(".content").hasClass("home")) {
        //     $(".content").removeClass('home');
        //     $(".home_page_main_full").parents(".content").addClass('home');
        //     window.location.reload(); 
        // }
        // else {
        //     $(".home_page_main_full").parents(".content").addClass('home');
        // }
        
        // $(".home_page_main_full").parents(".content").addClass('home');
        
        // $('html,body').animate({
            
        //     scrollTop: $(".content").offset().top - 80},
        //     'slow');
    });

    $('body a, body button,body input[type="button"], body #more_info a img').click(function(){
        
        $('html,body').animate({
            
            scrollTop: $("body").offset().top},
            'slow');
    });

    // It works when menu for mobile devices is showing. When clicking to any link on the menu the menu will hide afterward
    $(".nav-link").on("click", function () {
        $(".navbar-collapse").collapse('hide');
    });

    // window.homepagecheck = function() {
    //     var check = false;
    //     if(document.location.pathname === "/"){
    //       check=true;
    //       }
    //     return check;
    //   }

    //   if(window.homepagecheck()){
    //     // add code here
    //     $(".content").addClass('red');
    //   }
});

function App() {
    return (
        <div className="App">
            <Router>
                <div className="container">
                    <Header headers={headerSettings}/>
                    {/*<MenuBtn linkAttr={topNav}/>*/}
                    <div className="content index_all" > {/*don't remove index_all it's important for mobile view*/}
                        <Switch>
                            <Route path="/" exact component={() => <Home/>}/>
                            <Route path="/about-wills.html" exact component={() => <About/>}/>
                            <Route path="/contact.html" exact component={() => <Contact/>}/>
                            <Route path="/Option1.html" exact component={() => <Option1/>}/>
                            <Route path="/Option2.html" exact component={() => <Option2/>}/>
                            <Route path="/Option3.html" exact component={() => <Option3/>}/>
                            <Route path="/About-Wills1.html" exact component={() => <AboutWills1/>}/>
                            <Route path="/About-Wills2.html" exact component={() => <AboutWills2/>}/>
                            <Route path="/About-Wills3.html" exact component={() => <AboutWills3/>}/>
                            <Route path="/order-now.html" exact component={() => <OrderNow/>}/>
                            <Route path="/testimonials.html" exact component={() => <Testimonials/>}/>
                            <Route path="/contact.html" exact component={() => <Contact/>}/>
                            <Route path="/about-us.html" exact component={() => <AboutUs/>}/>
                            <Route path="/faq.html" exact component={() => <Faq/>}/>
                            <Route path="/example-form.html" exact component={() => <ExampleForm/>}/>
                            <Route path="/compare-prices1.html" exact component={() => <ComparePrices/>}/>
                            <Route path="/disclaimer.html" exact component={() => <Disclaimer/>}/>
                            <Route path="/terms.html" exact component={() => <Terms/>}/>
                            <Route path="/step1.html" exact component={() => <Step1/>}/>
                            <Route path="/step2.html" exact component={() => <Step2/>}/>
                            <Route path="/step3.html" exact component={() => <Step3/>}/>
                            <Route path="/example-will.html" exact component={() => <ExampleWill/>}/>
                            <Route path="/site-map.html" exact component={() => <SiteMap/>}/>
                        </Switch>
                    </div>
                    <Footer footers={footerSettings}/>
                </div>
            </Router>
        </div>
    );
}

export default App;