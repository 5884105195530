import React from "react";
import MetaTags from "react-meta-tags";

function Option1() {
    return (
        <div id="details">
            <MetaTags>
                <title>Testimonials- Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="Testimonials - Life Time Wills" />
            </MetaTags>
            <h1 className="page_title">Lifetime Wills Testimonials</h1>
            {/*<img src="/images/title_testimonials.gif" alt="Testimonials" />*/}
            <p>Currently, 99.7% of our customers say they are 'Very Satisfied' with our service.</p>
            <table cellPadding="0" cellSpacing="0" width="100%">
                
            </table>
        </div>
        );
}

export default Option1;