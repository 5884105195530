import React from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";

// popup alert
$(function () {
    $('[data-toggle="popover"]').popover();
});

function ComparePrices() {
    return (
        <div id="details">
            <MetaTags>
                <title>Compare prices - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="Compare prices - Life Time Wills" />
            </MetaTags>
            <h1 className="page_title">Option 1 - Comparing Services and Prices</h1>
            {/*<img src="/images/title_compare.gif" alt="" />*/}
                <p>You only have one Will, so choose the
                    highest quality, most comprehensive, value-for-money solution available
                    in the UK. This service should not be confused with cheaper or inferior
                    options that often lead clients to make expensive and time-consuming mistakes.
                    Compare other leading services and their prices:</p>

                    <table width="" border="0" className="board">
                        <thead>
                            <tr>
                                <td colSpan="14">
                                    <img src="/images/compare1.gif" className="img-compare-heading" alt="" />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td width="" align="left" className="backcell last-imp">Last Impressions</td>
                            <td width="" className="compare"><img src="/images/tick.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td width="" className="compare"><img src="/images/cross.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td width="" className="compare"><img src="/images/cross.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td width="" className="compare"><img src="/images/tick.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td width="" className="compare"><img src="/images/cross.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td width="" className="compare"><img src="/images/tick.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td width="" className="compare"><img src="/images/tick.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td width="" className="compare"><img src="/images/cross.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td width="" className="compare"><img src="/images/cross.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td width="" className="compare"><img src="/images/cross.gif" width="16" height="16"
                                                                    alt="" /></td>
                            <td className="compare-price c-one">£55.00</td>
                            <td className="compare-price c-two">£85.00</td>
                        </tr>
                        <tr>
                            <td align="left" className="backcell">The Will Site</td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare-price">£59.00</td>
                            <td className="compare-price">£88.00</td>
                        </tr>
                        <tr>
                            <td align="left" className="backcell">Will Drafters</td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare-price">£66.00</td>
                            <td className="compare-price">£99.00</td>
                        </tr>
                        <tr>
                            <td align="left" className="backcell">Just Wills PLC</td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/cross.gif" width="16" height="16" alt="" /></td>
                            <td className="compare-price">£82.25</td>
                            <td className="compare-price">£129.25</td>
                        </tr>
                        <tr>
                            <td align="left" className="backcell"><strong>LifeTime Wills</strong></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare"><img src="/images/tick.gif" width="16" height="16" alt="" /></td>
                            <td className="compare-price"><strong>£39.00</strong></td>
                            <td className="compare-price"><strong>£59.00</strong></td>
                        </tr>
                        </tbody>
                    </table>
                    <p>NB: The above figures are correct at the time of publishing</p>
                    <p>You can see from the above comparison chart that <strong>LifeTime Wills
                        offers <em>The UK's Premier Service</em></strong>. LifeTime Wills are an expert online
                        Will writing service - having considerably fewer overheads
                        than a high-street solicitor - this keeps costs down to a minimum,
                        allowing savings to be passed on to you, the customer.</p>
                    <div id="more_info" classNAme="more_info">
                        <p>Start making your Will now by clicking the 'order now' button…</p>
                        <Link to="/order-now.html"><img
                            src="/images/order.gif" name="Image13" border="0" alt="order now" /></Link>
                    </div>
                </div>
        );
}

export default ComparePrices;