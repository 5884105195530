import React from "react";
import {Link} from "react-router-dom";

function Header(props) {
    return (
        <div id="header">
            <div className="header_main row">
                
                {/* responsive menu moved from Bottom*/}
                <div className="sub-menu-header_icon">
                    <div className="menu-toggle">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-10 head_logo">
                    <div className="logo">
                        <Link to="/">
                            <img src={props.headers.imageLogoLink} alt="Lifetime Wills" border="0" title="Lifetime Wills"/>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 hidden-md-xs head_menu">
                    <div className="sidebar">
                        <ul>
                            <li className="">
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/about-wills.html" className="active">About Wills</Link>
                            </li>
                            <li>
                                <Link to="/faq.html">FAQs</Link>
                            </li>
                            <li>
                                <Link to="/order-now.html">Order now</Link>
                            </li>
                            <li>
                                <Link to="/about-us.html">About us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {/*Header Contact Us section*/}
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-3 head_contact">
                    <div className="bg_image_contact">
                        <span className="title">call us on</span>
                        <a href="tel:01656 508 262" className="call">01656 508 262</a>
                        <ul className="list-inline">
                            <li><Link to="/contact.html">Contact Us</Link></li>
                            <li>|</li>
                            <li><a href="https://tracker.lifetime-wills.com/login" target="_blank"  rel="noreferrer">Client Login</a></li>
                        </ul>
                    </div>         
                </div>
            </div>
            <div id ="submenu_toggle" className="row sub-menu-header">

                        <div className="head_submenu" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <Link className="nav-link active" to="/">Home <span className="sr-only">(current)</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about-wills.html">About Wills</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/option1.html">Instant Wills</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/option2.html">Instant Will plus</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/option3.html">Premier service</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about-wills3.html">Storage option</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/order-now.html">Order now</Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://tracker.lifetime-wills.com/login">Client Login</a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/testimonials.html">Testimonial</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact.html">Contact Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about-us.html">About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/faq.html">FAQs</Link>
                                </li>
                            </ul>
                        </div>
            </div>

            <div className="header_banner row">
                <div className="header_banner_txt col-8">
                    <h1>Why is a Will important?</h1>
                    <p className="hidden_mobile">It is sensible to make or change your Will after any significant changes in your life. <strong>For example,you should make a new Will if you are getting a divorce</strong></p>
                    <h4 className="show_mobile">We have a best will service in uk <span>from Just £39</span></h4>
                </div>
                <div className="header_banner_img col-4 text-right">
                        <Link to="/">
                            <img src={props.headers.headerBannerLink} alt="Lifetime Wills" border="0" title="Lifetime Wills"/>
                        </Link>
                </div>
            </div>
            
        </div>
    );
}

export default Header;